import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'

import { useProjectApi } from '../api/useProjectApi'
import { DeploymentSummaryDto } from '~/api/contracts/models'

export function useProjectDetailedDeploymentsQuery(projectId: Ref<string>) {
   const client = useProjectApi()
   return useQuery(
      [QueryKeys.RepositoryInfos, projectId], 
      () => client.listDeploymentsWithDetails(projectId.value),  {
         refetchInterval: 5000, enabled: projectId.value != ''
      })
}

export function useProjectDetailedDeploymentsQueryPrefetch(
   queryClient: QueryClient,
   projectId: Ref<string>
): Promise<void> {
   const client = useProjectApi()
   return queryClient.prefetchQuery<DeploymentSummaryDto[]>(
      [QueryKeys.RepositoryInfos, projectId],
      () => client.listDeploymentsWithDetails(projectId.value))
}
